/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ConfigProvider, message } from 'antd';
import { ChildAccountRoute, PrivateRoute, PublicRoute } from './routes';
import {
  AuthGoogle,
  CreateSchoolSetup,
  CurriculumSetup,
  EmailConformation,
  ForgetPassword,
  Payment,
  ResetPassword,
  SchoolSetup,
  SignedUp,
  SignIn,
  SignUp
} from './components/authComponents';
import {
  ParentAddChild,
  ParentAdditionalChild,
  ParentCancelMembership,
  ParentChildSetup,
  ParentConnectChild,
  ParentCurriculumSetup,
  ParentEmailConformation,
  ParentLastStep,
  ParentPayment,
  ParentPaymentStripe,
  ParentPersonalDetails,
  ParentSelectPlan,
  ParentSettings,
  ParentSignedUp,
  ParentSignIn,
  ReturnDashboard
} from './components/authParentComponents';
import {
  ChildAccount,
  ChildClassNotExist,
  ChildConfirm,
  ChildInfoGoogle,
  ChildLinkParent,
  ChildParentConnect,
  ChildParentConsent,
  ChildSignIn,
  ChildSignUp,
  ChildUpdateClass,
  ChildWherePlay
} from './components/authChildComponent';

import {
  EditAccount,
  EditChildAccount,
  EditMembership,
  Learning,
  MakePayment,
  ManageSubscriptions,
  ParentChildComprehensionReport,
  ParentChildCustomizePath,
  ParentChildWeeklyReports,
  ParentResources,
  Referrals,
  Subscriptions,
  UpdatePayment
} from './components/mainComponents';

import StudentSignIn from '@student-app/pages/StudentSignIn';

import { Dashboard, StudentDashboard, StudentDashboardDetail, TeacherDashboard } from '@pages/Dashboard';
import { NewPassword } from '@pages/TeacherSignUp/NewPassword';
import { Settings } from '@pages/Settings';
import { MembershipAndBilling } from '@pages/MembershipAndBilling';
import { NotFound } from '@pages/NotFound';
import { EdubytesDetail } from '@pages/Edubytes';
import { StudentComprehensionReport } from '@pages/StudentComprehensionReport';
import { Home } from '@pages/Home';

// New Teacher routes
import { NewPlanner, Planner } from '@teacher-app/pages/Planner';
import Edubytes from '@teacher-app/pages/Resources/Edubytes';
import Support from '@teacher-app/pages/Support';
import SupportGlossary from '@teacher-app/pages/SupportGlossary';
import InClassResources from '@teacher-app/pages/Resources/InClassResources';
import NewAssignment from '@teacher-app/pages/Assignment/NewAssignment';
import AssignmentReport from '@teacher-app/pages/AssignmentReport/AssignmentReport';
import Students from '@teacher-app/pages/Students/Students';
import Assignments from '@teacher-app/pages/Assignment/Assignments';

// New Parent routes
import ParentOverview from '@parent-app/pages/Overview';
import ChildAccounts from '@parent-app/pages/ChildAccount';
import Activities from '@parent-app/pages/Activities';

import { SignUpHome } from '@pages/SignUpHome';
import * as SchoolAdminSignup from '@pages/Signup/SchoolAdmin';
import StudentHelpCenter from '@student-app/pages/StudentHelpCenter';
import { TeachingResourcesDetail } from '@pages/TeachingResources';
import { Logout } from '@pages/Logout';
import { Referrals as TeacherReferrals } from '@pages/Referrals';
import { SelectLicence } from '@pages/Signup/Teacher';

import { awsConfig } from '@utils';
import { useDetectRouteChange, useDocumentTitle, usePageTracking } from '@hooks';
import './App.scss';
import { useModal } from '@context/modalContext';
import i18n from '@services/i18n';
import frLocale from 'antd/es/locale/fr_CA';
import enLocale from 'antd/es/locale/en_US';
import 'moment/locale/fr-ca';
import AuthCallback from '@teacher-app/pages/AuthCallback';

Amplify.configure(awsConfig());
message.config({
  duration: 10,
  maxCount: 1
});

const App = () => {
  usePageTracking();
  useDetectRouteChange();
  const { title } = useDocumentTitle();
  const { modalContent } = useModal();

  return (
    <ConfigProvider locale={i18n.language === 'fr-CA' ? frLocale : enLocale}>
      <HelmetProvider>
        <Helmet>
          <title>{title ? `${title} - ` : ''}Cyber Legends</title>
        </Helmet>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute restricted>
                <Home />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher-signin"
            element={
              <PublicRoute restricted>
                <SignIn />
              </PublicRoute>
            }
          />

          <Route
            path="/microsoft-authcallback"
            element={
              <PublicRoute restricted>
                <AuthCallback />
              </PublicRoute>
            }
          />

          <Route
            path="/student-signin"
            element={
              <PublicRoute restricted>
                <StudentSignIn />
              </PublicRoute>
            }
          />
          <Route
            path="/auth"
            element={
              <PublicRoute restricted>
                <AuthGoogle />
              </PublicRoute>
            }
          />
          <Route path="/teacher-create-account" element={<PublicRoute restricted />}>
            <Route index element={<SignUp />} />
            <Route path="select-licence" element={<SelectLicence />} />
          </Route>
          <Route
            path="/create-account-landing"
            element={
              <PublicRoute restricted>
                <SignUpHome />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute restricted>
                <ForgetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoute restricted>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher-create-account/email-confirmation"
            element={
              <PublicRoute restricted>
                <EmailConformation />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher-create-account/curriculum-setup"
            element={
              <PublicRoute restricted={false}>
                <CurriculumSetup />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher-create-account/school-setup"
            element={
              <PublicRoute restricted={false}>
                <SchoolSetup />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher-create-account/create-school"
            element={
              <PublicRoute restricted={false}>
                <CreateSchoolSetup />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher-create-account/payment-setup"
            element={
              <PublicRoute restricted>
                <Payment />
              </PublicRoute>
            }
          />
          <Route path="/teacher-create-account/signed-up" element={<SignedUp />} />
          <Route path="/teacher-signup" element={<PublicRoute restricted />}>
            <Route path="new-password" element={<NewPassword />} />
          </Route>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/parent-dashboard"
            element={
              <PrivateRoute>
                <ParentOverview />
              </PrivateRoute>
            }
          />
          <Route path="/student-dashboard" element={<PrivateRoute sidebar={false} marginContent={false} />}>
            <Route index element={<StudentDashboard />} />
            <Route path=":type/:id" element={<StudentDashboardDetail />} />
            <Route path="/student-dashboard/help-center" element={<StudentHelpCenter />} />
          </Route>
          <Route
            path="/teacher-dashboard"
            element={<PrivateRoute>{value => <TeacherDashboard {...value} />}</PrivateRoute>}
          />
          <Route
            path="/students"
            element={
              <PrivateRoute>
                <Students />
              </PrivateRoute>
            }
          />
          <Route path="/resources" element={<PrivateRoute />}>
            <Route index element={<InClassResources />} />
            <Route path=":id" element={<TeachingResourcesDetail />} />
          </Route>
          <Route path="/edubytes" element={<PrivateRoute />}>
            <Route index element={<Edubytes />} />
            <Route path=":id" element={<EdubytesDetail />} />
          </Route>
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route path="/assignments" element={<PrivateRoute />}>
            <Route index element={<Assignments />} />
            <Route path=":id" element={<NewAssignment />} />
            <Route path="report/:id" element={<AssignmentReport />} />
          </Route>
          <Route path="/planner" element={<PrivateRoute />}>
            <Route index element={<Planner />} />
            <Route path="new" element={<NewPlanner />} />
          </Route>
          <Route
            path="/student-comprehension"
            element={
              <PrivateRoute>
                <StudentComprehensionReport />
              </PrivateRoute>
            }
          />
          <Route path="/support" element={<PrivateRoute />}>
            <Route index element={<Support />} />
            <Route path="glossary-terms" element={<SupportGlossary />} />
          </Route>
          <Route path="/referrals-credits" element={<PrivateRoute />}>
            <Route index element={<TeacherReferrals />} />
          </Route>

          {/* Parent Sign In Flow */}
          <Route
            path="/parent-signin"
            element={
              <PublicRoute restricted>
                <ParentSignIn />
              </PublicRoute>
            }
          />
          <Route
            path="/parent-create-account"
            element={
              <PublicRoute restricted>
                <ParentPersonalDetails />
              </PublicRoute>
            }
          />
          <Route
            path="/parent-create-account/email-confirmation"
            element={
              <PublicRoute restricted>
                <ParentEmailConformation />
              </PublicRoute>
            }
          />
          <Route
            path="/parent-create-account/child-setup"
            element={
              <PublicRoute restricted={false}>
                <ParentChildSetup />
              </PublicRoute>
            }
          />
          <Route
            path="/parent-create-account/add-child"
            element={
              <PublicRoute restricted={false}>
                <ParentAddChild />
              </PublicRoute>
            }
          />
          <Route
            path="/parent-create-account/connect-child"
            element={
              <PublicRoute restricted={false}>
                <ParentConnectChild />
              </PublicRoute>
            }
          />
          <Route
            path="/parent-create-account/curriculum-setup"
            element={
              <PublicRoute restricted>
                <ParentCurriculumSetup />
              </PublicRoute>
            }
          />
          <Route
            path="/parent-create-account/additional-child"
            element={
              <PublicRoute restricted>
                <ParentAdditionalChild />
              </PublicRoute>
            }
          />
          <Route
            path="/parent-create-account/select-plan"
            element={
              <PublicRoute restricted>
                <ParentSelectPlan />
              </PublicRoute>
            }
          />
          <Route
            path="/parent-create-account/payment-setup"
            element={
              <PublicRoute restricted>
                <ParentPayment />
              </PublicRoute>
            }
          />
          <Route
            path="/parent-create-account/payment"
            element={
              <PublicRoute restricted>
                <ParentPaymentStripe />
              </PublicRoute>
            }
          />
          <Route
            path="/parent-create-account/payment-confirm"
            element={
              <PublicRoute restricted>
                <ParentLastStep />
              </PublicRoute>
            }
          />
          <Route
            path="/parent-create-account/confirmed"
            element={
              <PublicRoute restricted>
                <ParentSignedUp />
              </PublicRoute>
            }
          />
          <Route
            path="/referrals"
            element={
              <PrivateRoute>
                <Referrals />
              </PrivateRoute>
            }
          />
          <Route
            path="/child-accounts"
            element={
              <PrivateRoute>
                <ChildAccounts />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-child-account/:id"
            element={
              <PrivateRoute>
                <EditChildAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="/activities"
            element={
              <PrivateRoute>
                <Activities />
              </PrivateRoute>
            }
          />
          <Route
            path="/learning"
            element={
              <PrivateRoute>
                <Learning />
              </PrivateRoute>
            }
          />
          <Route
            path="/parent-resource/:id"
            element={
              <PrivateRoute>
                <ParentResources />
              </PrivateRoute>
            }
          />
          <Route
            path="/parent-child-comprehension-report"
            element={
              <PrivateRoute>
                <ParentChildComprehensionReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/parent-child-weekly-usage-report"
            element={
              <PrivateRoute>
                <ParentChildWeeklyReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/parent-child-customize-learning-path"
            element={
              <PrivateRoute>
                <ParentChildCustomizePath />
              </PrivateRoute>
            }
          />
          <Route
            path="/parent-setting"
            element={
              <PrivateRoute>
                <ParentSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/memberships-and-billing"
            element={
              <PrivateRoute>
                <MembershipAndBilling />
              </PrivateRoute>
            }
          />
          <Route
            path="/subscriptions"
            element={
              <PrivateRoute>
                <Subscriptions />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-subscription"
            element={
              <PrivateRoute>
                <ManageSubscriptions />
              </PrivateRoute>
            }
          />
          <Route
            path="/make-payment"
            element={
              <PrivateRoute>
                <MakePayment />
              </PrivateRoute>
            }
          />
          <Route
            path="/update-card"
            element={
              <PrivateRoute>
                <UpdatePayment />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-account"
            element={
              <PrivateRoute>
                <EditAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-membership/:id"
            element={
              <PrivateRoute>
                <EditMembership />
              </PrivateRoute>
            }
          />
          <Route
            path="/parent-cancel-membership"
            element={
              <PublicRoute>
                <ParentCancelMembership />
              </PublicRoute>
            }
          />
          <Route
            path="/downgrade-subscription-confirm"
            element={
              <PublicRoute>
                <ReturnDashboard />
              </PublicRoute>
            }
          />
          {/* Child Sign In Flow */}
          <Route
            path="/child-account"
            element={
              <ChildAccountRoute>
                <ChildAccount />
              </ChildAccountRoute>
            }
          />
          <Route
            path="/child-signin"
            element={
              <ChildAccountRoute>
                <ChildSignIn />
              </ChildAccountRoute>
            }
          />
          <Route
            path="/child-create-account"
            element={
              <ChildAccountRoute>
                <ChildSignUp />
              </ChildAccountRoute>
            }
          />
          <Route
            path="/child-create-account/parent-connect"
            element={
              <ChildAccountRoute>
                <ChildParentConnect />
              </ChildAccountRoute>
            }
          />
          <Route
            path="/child-create-account/confirm-account"
            element={
              <ChildAccountRoute>
                <ChildConfirm />
              </ChildAccountRoute>
            }
          />
          <Route
            path="/child-where-play"
            element={
              <ChildAccountRoute>
                <ChildWherePlay />
              </ChildAccountRoute>
            }
          />
          <Route
            path="/child-create-account/parent-consent"
            element={
              <ChildAccountRoute>
                <ChildParentConsent />
              </ChildAccountRoute>
            }
          />
          <Route
            path="/child-create-account/link-parent"
            element={
              <ChildAccountRoute>
                <ChildLinkParent />
              </ChildAccountRoute>
            }
          />
          <Route
            path="/child-create-account/class-not-exist"
            element={
              <ChildAccountRoute>
                <ChildClassNotExist />
              </ChildAccountRoute>
            }
          />
          <Route
            path="/child-create-account/info-update"
            element={
              <ChildAccountRoute>
                <ChildInfoGoogle />
              </ChildAccountRoute>
            }
          />
          <Route
            path="/child-create-account/update-class"
            element={
              <ChildAccountRoute>
                <ChildUpdateClass />
              </ChildAccountRoute>
            }
          />
          {/* School admin Signup flow */}
          <Route path="/school-admin/*" element={<PublicRoute restricted />}>
            <Route path="create-account" element={<SchoolAdminSignup.CreateAccount />} />
            <Route path="email-confirmation" element={<SchoolAdminSignup.EmailConfirmation />} />
            <Route path="school-setup" element={<SchoolAdminSignup.SchoolSetup />} />
            <Route path="create-school" element={<SchoolAdminSignup.CreateSchoolSetup />} />
          </Route>

          <Route path="/logout" element={<Logout />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        {modalContent}
      </HelmetProvider>
    </ConfigProvider>
  );
};

export default App;
