import React, { useEffect } from 'react';

const AuthCallback = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const authCode = params.get('code');
    const state = params.get('state');

    if (window.opener) {
      try {
        if (!authCode || !state) {
          const error = new Error('Missing authorization code or state');
          window.opener.postMessage({ error: error.message }, window.opener.location.origin);
          window.close();
          return;
        }

        window.opener.postMessage({ authCode, state }, window.opener.location.origin);
        window.close();
      } catch (error) {
        window.opener.postMessage({ error: error.message }, window.opener.location.origin);
        window.close();
      }
    }
  }, []);

  return (
    <div>
      <h1>Authenticating...</h1>
      <p>Please wait while we process your login.</p>
    </div>
  );
};

export default AuthCallback;
