import React, { useEffect, useState } from 'react';
import { Button, Modal, Stack, Text } from '@teacher-app/design-system';

import { getApiWithAuth, postApiWithAuth, REACT_APP_MICROSOFT_CLIENT_ID } from '@utils';
import { message, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useAppState } from '@context';
import { useTranslation } from 'react-i18next';
import useMicrosoftLogin from '@teacher-app/utils/useMicrosoftLogin';

const SCOPES = 'Calendars.Read Calendars.ReadWrite offline_access';
const { Option } = Select;

const MicrosoftCalendarSync = ({ isOpen, onClose }) => {
  const { state } = useAppState();
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });

  const [microsoftAccount, setMicrosoftAccount] = useState(null);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [calendars, setCalendars] = useState(null);
  const [selectedCourseLoading, setSelectedCourseLoading] = useState(false);

  const fetchMicrosoftLogin = async () => {
    const data = await getApiWithAuth('school/microsoft/oauth?scope=microsoft_calendar');
    if (data.success) {
      setMicrosoftAccount(true);
      fetchCalendars();
    } else {
      setMicrosoftAccount(false);
    }
  };

  const { login: microsoftLogin, loading: microsoftAccountLoading } = useMicrosoftLogin({
    clientId: REACT_APP_MICROSOFT_CLIENT_ID,
    redirectUri: `${window.location.origin}/microsoft-authcallback`,
    scopes: SCOPES,
    onSuccess: async ({ authCode, redirectUri }) => {
      const data = await postApiWithAuth('school/microsoft/oauth', {
        auth_code: authCode,
        scope: 'microsoft_calendar',
        redirect_uri: redirectUri
      });
      if (data.success) {
        setMicrosoftAccount(true);
        fetchCalendars();
      } else {
        throw new Error('Error occurred while fetching calendars');
      }
    },
    onError: error => message.error(error.message)
  });

  const fetchCalendars = async () => {
    const { data, success } = await getApiWithAuth(`school/microsoft/calendars?class_id=${state.classId}`);
    if (success) {
      setCalendars(data.value.filter(item => item.canEdit));
    } else {
      message.error(t('microsoft_fetch_calendar_error'));
    }
  };

  const postSelectedCalendar = async () => {
    setSelectedCourseLoading(true);
    const data = await postApiWithAuth('school/microsoft/calendars', {
      calendar_id: selectedCalendar,
      class_id: state.classId,
      scope: 'microsoft_calendar'
    });
    if (data.success) {
      setMicrosoftAccount(true);
      setSelectedCourseLoading(false);
      onClose();
    } else {
      setSelectedCourseLoading(false);
      message.error(t('microsoft_post_calendar_error'));
    }
  };

  const handleCalendarChange = values => {
    setSelectedCalendar(values);
  };

  useEffect(() => {
    fetchMicrosoftLogin();
  }, []);

  return (
    <Modal data-testid="student-login-details-modal" width={585} centered={false} onCancel={onClose} open={isOpen}>
      <Stack spacing={30} direction="column">
        <Stack alignItems="flex-start" style={{ flexWrap: 'wrap' }}>
          {microsoftAccount === false ? (
            <Stack spacing={30} direction="column">
              <Text bold size="large">
                {t('microsoft_authenticate_with_microsoft')}
              </Text>
              <Button block size="small" loading={microsoftAccountLoading} onClick={() => microsoftLogin()}>
                {t('microsoft_sync_with_microsoft')}
              </Button>
            </Stack>
          ) : calendars ? (
            <Stack spacing={30} direction="column">
              <Text bold size="large">
                {t('microsoft_select_calendar_to_sync')}
              </Text>
              <Select
                placeholder={t('microsoft_select_calendar')}
                value={selectedCalendar}
                className="fixCardInputField"
                onChange={handleCalendarChange}
                name="student_name">
                {calendars.map(item => (
                  <Option key={item.id} value={item.id} label={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>

              <Button block size="small" loading={selectedCourseLoading} onClick={() => postSelectedCalendar()}>
                {t('microsoft_next')}
              </Button>
            </Stack>
          ) : (
            <Stack style={{ paddingTop: 40, minHeight: 160 }} justifyContent="center">
              <Spin size="large" />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};

MicrosoftCalendarSync.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default MicrosoftCalendarSync;
