import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@teacher-app/design-system';
import { message, Modal, Spin } from 'antd';

import { Document, Image, Page, PDFViewer, StyleSheet, Text, View, Link } from '@react-pdf/renderer';
import assessmentArt from '@assets/images/assessment_pdf_art.png';
import logo from '@assets/images/new_logo.png';
import { API_URL, getApiWithAuth, REACT_APP_CYBER_ACADEMY_URL, REACT_APP_PARENT_HOME_URL } from '@utils';
import { useTranslation } from 'react-i18next';

export const styles = StyleSheet.create({
  logo: {
    height: 52,
    width: 100,
    position: 'absolute',
    top: 30,
    left: 50,
    zIndex: 10
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  assessmentArt: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 270,
    zIndex: 10
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  topContainer: {
    paddingRight: 50,
    paddingLeft: 50,
    paddingTop: 110,
    height: '50%'
  },
  heading: {
    fontSize: '28px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '800',
    color: '#222'
  },
  text: {
    fontSize: '13px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    color: '#3B3D3C'
  },
  subHeading: {
    fontSize: '15px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '800',
    color: '#222'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  leftColumn: {
    width: '47%'
  },
  rightColumn: {
    width: '47%'
  },
  stepRow: {
    flexDirection: 'row',
    width: '80%',
    marginBottom: 5,
    marginTop: 10
  },
  stepNumber: {
    fontSize: '45px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '800',
    color: '#674EFF',
    marginRight: 20
  },
  stepText: {
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '100%',
    color: '#3B3D3C'
  },
  textRow: {
    flexDirection: 'row',
    marginTop: 2,
    marginBottom: 2
  },
  textRowLeft: {
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '800',
    color: '#674EFF',
    marginRight: 5
  },
  textRowRight: {
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '100%',
    color: '#3B3D3C'
  },
  bottomContainer: {
    paddingRight: 50,
    paddingLeft: 50,
    paddingTop: 20,
    backgroundColor: '#F1F0FD',
    height: '50%'
  },
  link: {
    textDecoration: 'none',
  }
});

export const ParentLettersPdf = ({ isOpen, onClose, currentClass }) => {
  const [loading, setLoading] = useState(true);
  const [loginDetails, setLoginDetails] = useState(null);
  const { t } = useTranslation('translation', { keyPrefix: 'parent_letters_pdf' });

  useEffect(async () => {
    setLoading(true);
    const { success, data } = await getApiWithAuth(
      `${API_URL.EDIT_CLASS_BY_ID}${currentClass.id}/students/login-details`
    );

    if (success) {
      setLoginDetails(data);
      setLoading(false);
    } else {
      message.error(data.message);
      setLoading(false);
    }
  }, []);

  return (
    <Modal
      width="80vw"
      closable={false}
      centered
      footer={null}
      onCancel={onClose}
      open={isOpen}
      style={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}>
      {loading && (
        <Stack style={{ minHeight: 160 }} justifyContent="center">
          <Spin size="large" />
        </Stack>
      )}
      {!loading && loginDetails && (
        <Stack direction="column" alignItems="flex-start" spacing={12}>
          <PDFViewer style={{ width: '100%', height: '90vh' }}>
            <Document title={`Student Account Details - ${currentClass?.name}`}>
              {loginDetails.map((loginDetail, i) => (
                <Page key={i} size="A4">
                  <Image style={styles.assessmentArt} src={assessmentArt} />
                  <Image style={styles.logo} src={logo} />

                  <View key={i} style={styles.container}>
                    <View style={styles.topContainer}>
                      <View>
                        <Text style={styles.heading}>{t('heading')}</Text>
                        <Text style={styles.text}>{t('heading_text', { url: REACT_APP_CYBER_ACADEMY_URL })}</Text>
                      </View>
                      <View style={[styles.row, { marginTop: 15 }]}>
                        <View style={styles.leftColumn}>
                          <Text style={styles.subHeading}>{t('question_1')}</Text>
                          <Text style={styles.text}>{t('answer_1')}</Text>
                        </View>
                        <View style={styles.rightColumn}>
                          <Text style={styles.subHeading}>{t('question_2')}</Text>
                          <Text style={styles.text}>{t('answer_2')}</Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.bottomContainer}>
                      <View>
                        <Text style={styles.subHeading}>{t('list_1_heading')}</Text>
                        <Link style={styles.link} src={`${REACT_APP_PARENT_HOME_URL}/parent-create-account`}>
                          <Text style={styles.text}>{t('list_1_items_1', {url: `${REACT_APP_PARENT_HOME_URL}/parent-create-account`})}</Text>
                        </Link>
                      </View>
                      <View style={[styles.row, { marginTop: 20 }]}>
                        <View style={[styles.leftColumn, { width: '48%' }]}>
                          <Text style={styles.subHeading}>{t('list_2_heading')}</Text>
                          <View style={styles.stepRow}>
                            <Text style={styles.stepNumber}>1</Text>
                            <Link style={styles.link} src={REACT_APP_CYBER_ACADEMY_URL}>
                              <Text style={styles.stepText}>{t('list_2_items_1', { url: REACT_APP_CYBER_ACADEMY_URL })}</Text>
                            </Link>
                          </View>
                          <View style={styles.stepRow}>
                            <Text style={styles.stepNumber}>2</Text>
                            <Text style={styles.stepText}>{t('list_2_items_2')}</Text>
                          </View>
                        </View>
                        <View style={[styles.rightColumn, { width: '48%' }]}>
                          <Text style={styles.subHeading}>{t('detail_heading')}</Text>
                          <Text style={[styles.text, { marginTop: 10, marginBottom: 20 }]}>{t('detail_text')}</Text>
                          <View style={styles.textRow}>
                            <Text style={styles.textRowLeft}>{t('detail_name')}</Text>
                            <Text style={styles.textRowRight}>
                              {loginDetail.first_name} {loginDetail.last_name}
                            </Text>
                          </View>
                          <View style={styles.textRow}>
                            <Text style={styles.textRowLeft}>{t('detail_username')}</Text>
                            <Text style={styles.textRowRight}>{loginDetail.username}</Text>
                          </View>
                          <View style={styles.textRow}>
                            <Text style={styles.textRowLeft}>{t('detail_password')}</Text>
                            <Text style={styles.textRowRight}>{loginDetail.password}</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                  />
                </Page>
              ))}
            </Document>
          </PDFViewer>
        </Stack>
      )}
    </Modal>
  );
};

ParentLettersPdf.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentClass: PropTypes.object.isRequired
};
