import React, { useEffect, useState } from 'react';
import { Button, Modal, Stack, Table, Text } from '@teacher-app/design-system';

import { getApiWithAuth, postApiWithAuth, REACT_APP_MICROSOFT_CLIENT_ID } from '@utils';
import { message, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useAppState } from '@context';
import { useTranslation } from 'react-i18next';
import useMicrosoftLogin from '@teacher-app/utils/useMicrosoftLogin';

const SCOPES = 'EduRoster.Read EduRoster.ReadBasic offline_access';

const { Option } = Select;

const columns = t => [
  {
    title: t('microsoft_email'),
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: t('microsoft_first_name'),
    dataIndex: 'first_name',
    key: 'first_name'
  },
  {
    title: t('microsoft_last_name'),
    dataIndex: 'last_name',
    key: 'last_name'
  },
  {
    title: t('microsoft_courses'),
    dataIndex: 'class_names',
    key: 'class_names',
    render: class_names => class_names.join(', ')
  },
  {
    title: t('microsoft_enrolled'),
    dataIndex: 'already_enrolled',
    key: 'already_enrolled',
    render: already_enrolled => (already_enrolled ? '✔️' : '❌')
  }
];

const MicrosoftStudentSync = ({ isOpen, onClose }) => {
  const { state } = useAppState();
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });

  const [microsoftAccount, setMicrosoftAccount] = useState(null);
  const [updatedCourses, setUpdatedCourses] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState(null);
  const [courses, setCourses] = useState(null);
  const [allStudents, setAllStudents] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [syncStudentLoading, setSyncStudentLoading] = useState(false);
  const [selectedCourseLoading, setSelectedCourseLoading] = useState(false);

  const { login: microsoftLogin, loading: microsoftAccountLoading } = useMicrosoftLogin({
    clientId: REACT_APP_MICROSOFT_CLIENT_ID,
    redirectUri: `${window.location.origin}/microsoft-authcallback`,
    scopes: SCOPES,
    onSuccess: async ({ authCode, redirectUri }) => {
      const data = await postApiWithAuth('school/microsoft/oauth', {
        auth_code: authCode,
        scope: 'microsoft_classroom',
        redirect_uri: redirectUri
      });
      if (data.success) {
        setMicrosoftAccount(true);
        fetchCourses();
      } else {
        throw new Error('Error occurred while fetching courses');
      }
    },
    onError: error => message.error(error.message)
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedStudents(selectedRows);
    },
    getCheckboxProps: record => ({
      disabled: record['already_enrolled'],
      email: record.email
    })
  };

  const fetchMicrosoftLogin = async () => {
    const data = await getApiWithAuth('school/microsoft/oauth?scope=microsoft_classroom');
    if (data.success) {
      setMicrosoftAccount(true);
      fetchCourses();
    } else {
      setMicrosoftAccount(false);
    }
  };

  const fetchCourses = async () => {
    const { data, success } = await getApiWithAuth(`school/microsoft/classroom?class_id=${state.classId}`);
    if (success) {
      setCourses(data['value']);
      setSelectedCourses(data['selected_classes']);
    } else {
      message.error(t('microsoft_fetch_course_error'));
    }
  };

  const postSelectedCourse = async () => {
    setSelectedCourseLoading(true);
    const data = await postApiWithAuth('school/microsoft/classroom', {
      classes_ids: selectedCourses,
      class_id: state.classId,
      scope: 'microsoft_classroom'
    });
    if (data.success) {
      setMicrosoftAccount(true);
      setSelectedCourseLoading(false);
      setUpdatedCourses(true);
      fetchStudents();
    } else {
      setSelectedCourseLoading(false);
      message.error(t('microsoft_post_course_error'));
    }
  };

  const fetchStudents = async () => {
    const { data, success } = await getApiWithAuth(`school/microsoft/students?class_id=${state.classId}`);
    if (success) {
      const updatedStudents = data.map(student => ({
        ...student,
        key: student.email,
        class_names: student.classes_ids.map(classId => {
          const course = courses.find(course => course.id === classId);
          return course ? course.displayName : classId;
        })
      }));
      setAllStudents(updatedStudents);
    } else {
      message.error(t('microsoft_fetch_students_error'));
    }
  };

  const postSyncStudents = async () => {
    setSyncStudentLoading(true);
    const data = await postApiWithAuth('school/microsoft/students', {
      class_id: state.classId,
      students: selectedStudents
    });
    if (data.success) {
      setSyncStudentLoading(false);
      fetchStudents();
    } else {
      setSyncStudentLoading(false);
      message.error(t('microsoft_post_students_error'));
    }
  };

  const handleCoursesChange = values => {
    setSelectedCourses(values);
  };

  useEffect(() => {
    fetchMicrosoftLogin();
  }, []);

  return (
    <Modal data-testid="student-login-details-modal" width={585} centered={false} onCancel={onClose} open={isOpen}>
      <Stack spacing={30} direction="column">
        <Stack alignItems="flex-start" style={{ flexWrap: 'wrap' }}>
          {microsoftAccount === false ? (
            <Stack spacing={30} direction="column">
              <Text bold size="large">
                {t('microsoft_authenticate_with_microsoft')}
              </Text>
              <Button block size="small" loading={microsoftAccountLoading} onClick={() => microsoftLogin()}>
                {t('microsoft_sync_with_microsoft')}
              </Button>
            </Stack>
          ) : courses && !updatedCourses ? (
            <Stack spacing={30} direction="column">
              <Text bold size="large">
                {t('microsoft_select_courses_to_sync')}
              </Text>
              <Select
                mode="multiple"
                placeholder={t('microsoft_select_courses')}
                value={selectedCourses}
                className="fixCardInputField"
                onChange={handleCoursesChange}
                name="student_name">
                {courses.map(item => (
                  <Option key={item.id} value={item.id} label={item.displayName}>
                    {item.displayName}
                  </Option>
                ))}
              </Select>

              <Button block size="small" loading={selectedCourseLoading} onClick={() => postSelectedCourse()}>
                {t('microsoft_next')}
              </Button>
            </Stack>
          ) : allStudents ? (
            <Stack spacing={30} direction="column">
              <Text bold size="large">
                {t('microsoft_select_students')}
              </Text>
              {!syncStudentLoading && (
                <Table
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection
                  }}
                  dataSource={allStudents}
                  columns={columns(t)}
                />
              )}
              <Button block size="small" loading={syncStudentLoading} onClick={() => postSyncStudents()}>
                {t('microsoft_sync_students')}
              </Button>
            </Stack>
          ) : (
            <Stack style={{ paddingTop: 40, minHeight: 160 }} justifyContent="center">
              <Spin size="large" />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};

MicrosoftStudentSync.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default MicrosoftStudentSync;
