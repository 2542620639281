import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Navbar } from '@components/layoutComponents';

import teacherImage from '@assets/images/icon_gradHat.png';
import homeschoolImage from '@assets/images/icon_homeSchool.png';
import schoolImage from '@assets/images/icon_pencil.png';
import schoolBoardImage from '@assets/images/icon_book.png';
import parentImage from '@assets/images/icon_lightBulb.png';
import rightArrow from '@assets/images/rightArrow.svg';

import './Home.scss';
import {
  REACT_APP_CYBER_ACADEMY_URL,
  REACT_APP_PARENT_HOME_URL,
  removeLocalStorageItem,
  setLocalStorageItem
} from '@utils';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

export const Home = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'home' });
  const navigate = useNavigate();
  const { flagsReady } = useFlagsStatus();
  const parentEnabled = useFlag('parent.dashboard_v2');

  useEffect(() => {
    removeLocalStorageItem('isHomeschoolTeacher');
  }, []);

  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="innerDivHome mt-5">
        <div className="homeHeading">{t('choose_account_type')}</div>
        <div className="d-flex mt-3 flex-wrap justify-content-center gap-2">
          {flagsReady && parentEnabled && (
            <div className="cardHome me-3">
              <div className="cardHeadingHome">{t('parent')}</div>
              <div>
                <img src={parentImage} alt="parentImage" width="92" height="87" />
              </div>
              <div
                className="cardGetStartedHome"
                onClick={() => {
                  window.location.href = REACT_APP_PARENT_HOME_URL;
                }}>
                {t('get_started')} &nbsp; <img src={rightArrow} alt="rightArrow" />
              </div>
            </div>
          )}
          <div className="cardHome me-3">
            <div className="cardHeadingHome">{t('homeschool_teacher')}</div>
            <div>
              <img src={homeschoolImage} alt="homeschoolTeacherImage" width="115" height="87" />
            </div>
            <div
              className="cardGetStartedHome"
              onClick={() => {
                setLocalStorageItem('isHomeschoolTeacher', true);
                navigate('/teacher-signin');
              }}>
              {t('get_started')} &nbsp; <img src={rightArrow} alt="rightArrow" />
            </div>
          </div>
          <div className="cardHome me-3">
            <div className="cardHeadingHome">{t('teacher')}</div>
            <div>
              <img src={teacherImage} alt="teacherImage" width="115" height="87" />
            </div>
            <div
              className="cardGetStartedHome"
              onClick={() => {
                navigate('/teacher-signin');
              }}>
              {t('get_started')} &nbsp; <img src={rightArrow} alt="rightArrow" />
            </div>
          </div>
          {/*<div className="cardHome me-3">
            <div className="cardHeadingHome">{t('school_board')}</div>
            <div>
              <img src={schoolBoardImage} alt="studentImage" width="90" height="87" />
            </div>
            <a
              className="cardGetStartedHome"
              href="https://www.cyberlegends.com/memberships/school-board-sign-up"
              target="_blank"
              rel="noreferrer">
              {t('get_started')} &nbsp; <img src={rightArrow} alt="rightArrow" />
            </a>
          </div>*/}
          <div className="cardHome me-3">
            <div className="cardHeadingHome">{t('student')}</div>
            <div>
              <img src={schoolImage} alt="studentImage" width="92" height="87" />
            </div>
            <div
              className="cardGetStartedHome"
              onClick={() => {
                window.location.href = REACT_APP_CYBER_ACADEMY_URL;
              }}>
              {t('get_started')} &nbsp; <img src={rightArrow} alt="rightArrow" />
            </div>
          </div>
          <div className="cardHome me-3">
            <div className="cardHeadingHome">{t('admin')}</div>
            <div>
              <img src={schoolBoardImage} alt="pencil image" width="90" height="87" />
            </div>
            <div
              className="cardGetStartedHome"
              onClick={() => {
                navigate('/school-admin/create-account');
              }}>
              {t('get_started')} &nbsp; <img src={rightArrow} alt="rightArrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
