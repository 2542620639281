import { useCallback, useState } from 'react';

const useMicrosoftLogin = ({ clientId, redirectUri, scopes, onSuccess, onError }) => {
  const [loading, setLoading] = useState(false);

  const openAuthPopup = useCallback((authUrl, width = 600, height = 700) => {
    const left = window.screenX + (window.innerWidth - width) / 2;
    const top = window.screenY + (window.innerHeight - height) / 2;

    return window.open(authUrl, 'authPopup', `width=${width},height=${height},top=${top},left=${left}`);
  }, []);

  const buildAuthUrl = useCallback(() => {
    const params = new URLSearchParams({
      client_id: clientId,
      response_type: 'code',
      redirect_uri: redirectUri,
      response_mode: 'query',
      scope: scopes,
      state: 'customStateValue' // Customize as needed
    });

    return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${params.toString()}`;
  }, [clientId, redirectUri, scopes]);

  const handleAuthPopup = useCallback(() => {
    const authUrl = buildAuthUrl();
    const authPopup = openAuthPopup(authUrl);

    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        try {
          if (!authPopup || authPopup.closed) {
            clearInterval(interval);
            reject(new Error('Popup closed by user'));
            return;
          }

          const popupUrl = authPopup.location.href;
          if (popupUrl.includes('code=')) {
            clearInterval(interval);

            try {
              const urlParams = new URLSearchParams(new URL(popupUrl).search);
              const authCode = urlParams.get('code');
              const state = urlParams.get('state');

              if (!authCode || !state) {
                throw new Error('Missing authorization code or state');
              }

              authPopup.close();
              resolve({ authCode, state });
            } catch (parsingError) {
              reject(new Error('Failed to parse authentication response'));
              authPopup.close();
            }
          }
        } catch (error) {
          // Ignore cross-origin frame access errors until the redirect happens
        }
      }, 500);
    });
  }, [buildAuthUrl, openAuthPopup]);

  const login = useCallback(async () => {
    try {
      setLoading(true);
      const { authCode, state } = await handleAuthPopup();

      if (!authCode || !state) {
        throw new Error('Missing authorization code or state');
      }

      if (onSuccess) {
        await onSuccess({ authCode, state, redirectUri });
      }
    } catch (error) {
      if (onError) {
        onError(error);
      } else {
        throw new Error(`Authentication failed: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  }, [handleAuthPopup, onSuccess, onError, redirectUri]);

  return { login, loading };
};

export default useMicrosoftLogin;
