import { PublicClientApplication } from '@azure/msal-browser';
import { REACT_APP_MICROSOFT_CLIENT_ID, REACT_APP_MICROSOFT_REDIRECT } from '@utils';

const MSAL_CONFIG = {
  auth: {
    clientId: REACT_APP_MICROSOFT_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common/v2.0',
    redirectUri: REACT_APP_MICROSOFT_REDIRECT
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

export const msalInstance = new PublicClientApplication(MSAL_CONFIG);

export const SINGLE_SIGN_ON_PROVIDER = {
  MICROSOFT: 'MICROSOFT'
};
