import React, { useEffect, useState } from 'react';
import { Button, Modal, Stack, Text } from '@teacher-app/design-system';

import { useGoogleLogin } from '@react-oauth/google';
import { getApiWithAuth, postApiWithAuth } from '@utils';
import { message, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useAppState } from '@context';
import { useTranslation } from 'react-i18next';

const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events';

const { Option } = Select;

const GoogleCalendarSync = ({ isOpen, onClose }) => {
  const { state } = useAppState();
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });

  const [googleAccount, setGoogleAccount] = useState(null);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [calendars, setCalendars] = useState(null);
  const [googleAccountLoading, setGoogleAccountLoading] = useState(false);
  const [selectedCourseLoading, setSelectedCourseLoading] = useState(false);

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: SCOPES,
    onSuccess: async codeResponse => {
      setGoogleAccountLoading(true);
      const data = await postApiWithAuth('school/google/oauth', {
        auth_code: codeResponse.code,
        scope: 'google_calendar'
      });
      if (data.success) {
        setGoogleAccountLoading(false);
        setGoogleAccount(true);
        fetchCalendars();
      } else {
        setGoogleAccountLoading(false);
        message.error('Error');
      }
    },
    onError: errorResponse => message.error(errorResponse)
  });

  const fetchGoogleLogin = async () => {
    const data = await getApiWithAuth('school/google/oauth?scope=google_calendar');
    if (data.success) {
      setGoogleAccount(true);
      fetchCalendars();
    } else {
      setGoogleAccount(false);
    }
  };

  const fetchCalendars = async () => {
    const { data, success } = await getApiWithAuth(`school/google/calendars?class_id=${state.classId}`);
    if (success) {
      setCalendars(data.items.filter(item => item.accessRole === 'owner'));
    } else {
      message.error(t('google_fetch_calendar_error'));
    }
  };

  const postSelectedCalendar = async () => {
    setSelectedCourseLoading(true);
    const data = await postApiWithAuth('school/google/calendars', {
      calendar_id: selectedCalendar,
      class_id: state.classId,
      scope: 'google_calendar'
    });
    if (data.success) {
      setGoogleAccount(true);
      setSelectedCourseLoading(false);
      onClose();
    } else {
      setSelectedCourseLoading(false);
      message.error(t('google_post_calendar_error'));
    }
  };

  const handleCalendarChange = values => {
    setSelectedCalendar(values);
  };

  useEffect(() => {
    fetchGoogleLogin();
  }, []);

  return (
    <Modal data-testid="student-login-details-modal" width={585} centered={false} onCancel={onClose} open={isOpen}>
      <Stack spacing={30} direction="column">
        <Stack alignItems="flex-start" style={{ flexWrap: 'wrap' }}>
          {googleAccount === false ? (
            <Stack spacing={30} direction="column">
              <Text bold size="large">
                {t('google_authenticate_with_google')}
              </Text>
              <Button block size="small" loading={googleAccountLoading} onClick={() => googleLogin()}>
                {t('google_sync_with_google')}
              </Button>
            </Stack>
          ) : calendars ? (
            <Stack spacing={30} direction="column">
              <Text bold size="large">
                {t('google_select_calendars_to_sync')}
              </Text>
              <Select
                placeholder={t('google_select_calendars')}
                value={selectedCalendar}
                className="fixCardInputField"
                onChange={handleCalendarChange}
                name="student_name">
                {calendars.map(item => (
                  <Option key={item.id} value={item.id} label={item.summary}>
                    {item.summary}
                  </Option>
                ))}
              </Select>

              <Button block size="small" loading={selectedCourseLoading} onClick={() => postSelectedCalendar()}>
                {t('google_next')}
              </Button>
            </Stack>
          ) : (
            <Stack style={{ paddingTop: 40, minHeight: 160 }} justifyContent="center">
              <Spin size="large" />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};

GoogleCalendarSync.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default GoogleCalendarSync;
